import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import MindanceAppSection from "src/components/marketing/mindance-app-section"
import SignatureSection from "src/components/marketing/signature-section"
import "src/styles/pages/marketing-page.scss"
import TitleStrip from "src/components/marketing/title-strip"
import SEO from "src/components/seo"

export default function TeambankLandingPage() {
  return (
    <Layout className="marketing-page-root">
      <TitleStrip
        logos={[
          "/assets/img/logos/Teambank.png",
          "/assets/img/marketing/mindance_anmeldung.png",
        ]}
      />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title:
            "Endlich weniger Stress, besserer Schlaf und ein gesteigertes Wohlbefinden!",
          cta_text: "Zum Digitalen Campus der Teambank",
          cta_link:
            "https://teambank.mein-helix.de/#/e-learning-lesson/details/388/-1",
          description:
            "Mitarbeitende der Teambank erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Den Aktivierungscode für Ihre Mindance App finden Sie im Digitalen Campus der Teambank.",
        }}
      ></Jumbotron>
      <SEO
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
        title="Teambank"
        description="Mitarbeitende der Teambank erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Den Aktivierungscode für Ihre Mindance App finden Sie im Digitalen Campus der Teambank."
      />
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h1>Stress reduzieren und Wohlbefinden steigern</h1>
          <p>
            Zeitdruck, Emotionsarbeit und Schichtdienste - die Arbeit bei der
            Teambank kann neben vielen schönen Momenten auch für Erschöpfung,
            Frustration und Stress sorgen. Hinzu kommen in der Freizeit noch
            private Herausforderungen. Diese Belastungen führen auf Dauer zur
            Beeinträchtigung der körperlichen und psychischen Gesundheit -
            Burnout, Schlafstörungen, Rückenschmerzen, Herz-Kreislauf Probleme
            und Depressionen sind nur einige Beispiele für negative Folgen von
            Dauerbelastungen.
          </p>
          <br />
          <p>
            <b>
              Die gute Nachricht: Mit Mindance können Sie Stress reduzieren und
              Ihr Wohlbefinden nachhaltig steigern!
            </b>
          </p>
          <br />
          <h2 className="green-text">
            Kostenlos für alle Mitarbeitenden der Teambank
          </h2>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/marketing/mindfrinds_achtsamkeit_reflection.jpg"
          />
        </Grid>
      </Grid>
      <MindanceAppSection />
      <SignatureSection />
    </Layout>
  )
}
